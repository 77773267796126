import { Grid, Typography } from '@material-ui/core'
import { Link, navigate } from 'gatsby'
import { TFunction } from 'i18next'
import React, { useCallback, useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import searchIcon from '../assets/images/search.svg'
import CHRContentContainer from '../components/contentContainer'
import CHRInput from '../components/input'
import CHROutlinedButton from '../components/outlinedButton'
import SEO from '../components/seo'
import TheChirpyestCard from '../components/theChirpyestCard'
import Layout from '../layouts/defaultLayout'
import { styles } from '../pagesStyles/theChirpyestStyle'
// import CHRSort from '../components/CHRSort'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import Links from '@material-ui/core/Link'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import { useLocation } from '@reach/router'
import debounce from 'lodash.debounce'
import { parse } from 'query-string'
import axios from '../axios'
import CHRSpinner from '../components/spinner'
import { CSEvents, ENDPOINTS, ROUTES } from '../constants'
import { tracker } from '../systemLogs'
import NotFound from './404'
interface TheChirpyestProps {
  t: TFunction
}

const DEFAULT_SIZE = 30
const TheChirpyest = ({ t }: TheChirpyestProps) => {
  const classes = styles()
  const { search } = useLocation()
  const { q } = parse(search)
  const [usersResults, setUsersResults] = useState({
    data: [],
    finishedLoading: true,
    offset: 0,
    size: DEFAULT_SIZE,
  })
  const [searchText, setSearchText] = useState(q || '')
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState('')
  const [showScrollBtn, setShowScrollBtn] = useState(false)

  useEffect(() => {
    const prevState = sessionStorage.getItem('the-chirpyest')
    if (prevState) {
      setUsersResults(JSON.parse(prevState))
      setIsLoading(false)
      sessionStorage.removeItem('the-chirpyest')

      const scrollPos = sessionStorage.getItem('the-chirpyest-scroll-pos')
      setTimeout(() => window.scrollTo(0, Number(scrollPos)), 100)
      sessionStorage.removeItem('the-chirpyest-scroll-pos')
    } else {
      getUsersResults()
    }
  }, [])

  const getUsersResults = async () => {
    try {
      const {
        data: { data: results },
      } = await axios.get(
        `${ENDPOINTS.getTheChirpyest}?offset=${usersResults.offset}&size=${usersResults.size}&q=${searchText}`
      )
      setUsersResults({
        finishedLoading:
          results.length === 0 || results.length < usersResults.size,
        offset: usersResults.offset + usersResults.size,
        size: DEFAULT_SIZE,
        data: usersResults.data.concat(results.sort(() => 0.5 - Math.random())),
      })
    } catch (err) {
      setError(t('messages.somethingWentWrong'))
    }

    setIsLoading(false)
  }

  const handleButtonSearch = () => {}

  const fetchResults = async (query: string) => {
    try {
      const {
        data: { data: results },
      } = await axios.get(
        `${ENDPOINTS.getTheChirpyest}?offset=0&size=${usersResults.size}&q=${query}`
      )
      setUsersResults({
        finishedLoading:
          results.length === 0 || results.length < usersResults.size,
        offset: usersResults.size,
        size: DEFAULT_SIZE,
        data: results.sort(() => 0.5 - Math.random()),
      })
    } catch (err) {
      setError(t('messages.somethingWentWrong'))
    }
  }

  const debouncedSave = useCallback(
    debounce((q: string) => fetchResults(q), 300),
    []
  )

  const updateValue = (q: string) => {
    setSearchText(q)
    debouncedSave(q)
  }

  const goToShoppingBoard = (username: string) => {
    sessionStorage.setItem('the-chirpyest', JSON.stringify(usersResults))
    sessionStorage.setItem('the-chirpyest-scroll-pos', `${window.scrollY}`)
    navigate(`/s/${username}`)
  }

  if (error) {
    return <NotFound error={error} />
  }

  useEffect(() => {
    window.onscroll = () => {
      const minScroll = 150
      if (
        document.body.scrollTop >= minScroll ||
        document.documentElement.scrollTop >= minScroll
      ) {
        setShowScrollBtn(true)
      } else {
        setShowScrollBtn(false)
      }
    }
    return () => {
      window.onscroll = null
    }
  }, [])

  const scrollUp = () => {
    window.scroll({
      top: 0,
      behavior: 'smooth',
    })
  }

  const trackClickStream = async (username: string) => {
    await tracker.trackCs(CSEvents.viewBoard, {
      pageUrl: window.location.href,
      shoppingBoardUsername: username,
      section: 'chirpyest-community',
    })
  }

  return (
    <Layout>
      <SEO
        title={t('seo.theChirpyest.title')}
        description={t('seo.theChirpyest.description')}
      />
      <CHRContentContainer>
        <div className={classes.header}>
          <div className={classes.leftSide}>
            <Breadcrumbs aria-label="breadcrumb">
              <Links
                color="inherit"
                rel="preconnect"
                onClick={(
                  event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
                ) => {
                  event.preventDefault()
                  navigate(ROUTES.home)
                }}
              >
                home
              </Links>
              <Typography color="textPrimary">chirpyest community</Typography>
            </Breadcrumbs>
            <Typography variant="h4" className={classes.theChirpyest}>
              chirpyest community
            </Typography>
            <Typography
              variant="subtitle1"
              component="p"
              className={classes.description}
            >
              {t('theChirpyest.metaDescription')}
            </Typography>

            <Typography
              component="h1"
              variant="h1"
              className={classes.seoHeading}
            >
              {t('seo.theChirpyest.h1')}
            </Typography>
          </div>
          <div className={classes.headerRightSide}>
            <div className={classes.searchWrapper}>
              <CHRInput
                type="text"
                value={searchText}
                placeholder="search the chirpyest community"
                onChange={e => updateValue(e.target.value)}
              />
              <button
                className={classes.searchButton}
                onClick={handleButtonSearch}
              >
                <img
                  src={searchIcon}
                  alt={t('imageAlts.search')}
                  className={classes.searchIcon}
                />
              </button>
            </div>
          </div>
        </div>
        {isLoading ? (
          <CHRSpinner />
        ) : (
          <Grid
            container
            alignItems="stretch"
            spacing={2}
            className={classes.mainGrid}
          >
            {usersResults.data.map(
              ({
                user_id: userId,
                image: userImage,
                items,
                description,
                username,
                short_bio: blurb,
              }) => (
                <Grid
                  item
                  lg={4}
                  md={4}
                  sm={6}
                  xs={12}
                  key={userId}
                  onClick={() => trackClickStream(username)}
                >
                  <Link
                    rel="preconnect"
                    to={`/s/${username}`}
                    className={classes.link}
                  >
                    <TheChirpyestCard
                      userImage={userImage}
                      items={items}
                      description={description}
                      userName={username}
                      blurb={blurb}
                      onClick={() => goToShoppingBoard(username)}
                    />
                  </Link>
                </Grid>
              )
            )}
          </Grid>
        )}
        {!isLoading && !usersResults.data.length && (
          <Typography variant="h3" className={classes.noData}>
            sorry, there is no data
          </Typography>
        )}
        {!usersResults.finishedLoading && (
          <CHROutlinedButton
            label={t('dashboard.loadMore')}
            onClick={getUsersResults}
          />
        )}
      </CHRContentContainer>
      <button
        onClick={scrollUp}
        className={`${classes.scrollButton} ${
          showScrollBtn ? '' : classes.hidden
        }`}
      >
        <ArrowUpwardIcon fontSize="large" />
      </button>
    </Layout>
  )
}

export default withTranslation()(TheChirpyest)
