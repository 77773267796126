import React, { useState } from 'react'
import { Typography } from '@material-ui/core'
import { styles } from './styles'
import IgImageView from '../IgImageView'
import BirdAvatar from './../../assets/images/chirpyest-logo.svg'

const TheChirpyestCard = ({
  userImage,
  items,
  userName,
  description,
  blurb,
  onClick,
}) => {
  const classes = styles()
  const [random] = useState(Math.random())

  return (
    <div className={classes.gridItem} onClick={onClick}>
      <div className={classes.imageWrapper}>
        {userImage ? (
          <img
            src={`https://${process.env.GATSBY_S3_BUCKET_NAME}.s3.us-east-2.amazonaws.com/public/${userImage}`}
            alt="user profile image"
            className={classes.imageHalfWidth}
          />
        ) : (
          <div
            className={classes.imageHalfWidth}
            style={{
              backgroundColor: random < 0.5 ? '#85ACA3' : '#F27870',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <img
              src={BirdAvatar}
              style={{
                filter:
                  random < 0.5
                    ? 'invert(87%) sepia(16%) saturate(162%) hue-rotate(121deg) brightness(93%) contrast(92%)'
                    : 'invert(78%) sepia(17%) saturate(602%) hue-rotate(311deg) brightness(102%) contrast(95%)',
              }}
            />
          </div>
        )}
        {items && items[0] ? (
          items[0].type === 'instagram' ? (
            <IgImageView
              url={items[0].data.url || items[0].data.link}
              className={classes.imageHalfWidth}
            />
          ) : (
            <img
              src={items[0].data.imageUrl}
              alt="product image 1"
              className={classes.imageHalfWidth}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null // prevents looping
                currentTarget.classList.remove(classes.imageHalfWidth)
                currentTarget.classList.add(classes.errorClass)
                currentTarget.src = BirdAvatar
              }}
            />
          )
        ) : (
          <div
            className={classes.imageHalfWidth}
            style={{ backgroundColor: random < 0.5 ? '#BFD3D0' : '#F9BEBF' }}
          />
        )}
      </div>
      <div className={classes.imageWrapper}>
        {[1, 2, 3].map(index => {
          return items && items[index] ? (
            items[index].type === 'instagram' ? (
              <IgImageView
                url={items[index].data.url || items[index].data.link}
                className={classes.image}
              />
            ) : (
              <img
                src={items[index].data.imageUrl || items[index].data.base64}
                alt="product image 3"
                className={classes.image}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null // prevents looping
                  currentTarget.classList.remove(classes.image)
                  currentTarget.classList.add(classes.errorImage)
                  currentTarget.src = BirdAvatar
                }}
              />
            )
          ) : (
            <div
              className={classes.image}
              style={{ backgroundColor: '#F5F6F7' }}
            />
          )
        })}
      </div>
      <Typography variant="subtitle1" className={classes.description}>
        {description}
      </Typography>
      <Typography variant="h2" className={classes.edgeSpaces}>
        {userName}
      </Typography>
      <Typography className={classes.blurb}>{blurb}</Typography>
    </div>
  )
}

export default TheChirpyestCard
